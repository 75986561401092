module.exports = {
  name: 'M.Hassan Ahmed',
  shortName: 'Hassan',
  description:
    'A Python Enthusiast, Data Scientist and Full Stack Web Developer',
  url: 'https://www.hassan11196.code', // Domain of your site without prefix!
  prefix: '/',
  author: 'Hassan11196',
  twitter: '',
  fiverr: 'https://www.fiverr.com/hassanahmed19',
  interests:
    'A Python Enthusiast, Data Science Beginner, Full Stack Web Developer and a Final Year student at FAST National University, Karachi. with experience in Web development Technologies such as: Django, ReactJS, Gatsby, Angular, Flask, REST-API Development, SQlite, PostgreSQL, MongoDB, FireBase etc. Experienced with Cloud Platforms such as Google Cloud Platform, Heroku, Pythonanywhere, Azure,etc; other interests include : Big data, Apache Spark,C/C++, Linux shell scripting, QT GUI, Flutter App Development, MIT-Appinventor, Arduino programming etc.',
  medium: 'https://medium.com/@hassan11196',
  freelancer: 'https://www.freelancer.com/u/hassan11196',
  facebook: 'https://www.facebook.com/hassan11196',
  github: 'https://www.github.com/hassan11196',
  hackerrank: 'https://www.hackerrank.com/hassan11196',
  linkedin: 'https://www.linkedin.com/in/hassan11196/',
  kaggle: 'https://www.kaggle.com/hassan11196',
  sourceer: 'https://sourcerer.io/hassan11196'
};
